
/* 

Código elaborado por: Nelson Braga
Cargo: Desenvolvedor Fullstack
Data: 22/03/2024 

*/

@keyframes float {
    0% {
        transform: translate3d(0em, 0em, 0em);
    }

    12% {
        transform: translate3d(0.0625em, 0.0625em, 0.0625em);
    }

    25% {
        transform: translate3d(0.125em, 0.125em, 0.125em);        
    }

    37% {
        transform: translate3d(0.1875em, 0.1875em, 0.1875em);        
    }

    50% {
        transform: translate3d(0.25em, 0.25em, 0.25em);
    }

    62% {
        transform: translate3d(0.1875em, 0.1875em, 0.1875em);
    }

    75% {
        transform: translate3d(0.125em, 0.125em, 0.125em);
    }

    87% {
        transform: translate3d(0.0625em, 0.0625em, 0.0625em);
    }

    100% {
        transform: translate3d(0em, 0em, 0em);
    }
}

#tituloLocal, #descConsumidor {
    width: 100%;
    font-weight: 700;
    text-align: center;    
    color: rgb(240, 233, 233);
    padding: 0.625em;
    border-radius: 1.875em;
    border-color: #4473c44d;
    background-color: #4473c44d;
    margin-bottom: 0.5em;
}

.lblAdicionarLocal {
    font-size: 1.1em;
    font-weight: 500;
    color: white;
    padding: 0.625em 1.25em 0.625em 0.625em;
}

.adicionalLocal {
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    margin-top: 0.625em;
    margin-bottom: 1.875em;    
    background-color: rgb(35, 212, 35);
    border: outset 0.0625em white;
    width: 3.75em;
    border-radius: 0.625em;
}

.adicionalLocal:hover {
    background-color: rgb(12, 163, 12);
    border: solid 0.125em white;
    box-shadow: 0.125em 0.125em 0.5em rgb(207, 206, 206);    
}

.logotipo {
    width: 10em;
    height: 5em;
}

p {
    color: aliceblue;
    font-weight: 500;
    letter-spacing: 0.125em;
    font-family: Arial, Helvetica, sans-serif
}

#whatsappButton {
    transform: translate3d(0, 0, 0);
    animation: float 3s ease-in-out infinite;
}

#whatsappImg {
    width:2.875em;
    height:2.875em
}