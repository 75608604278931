
/* 

Código elaborado por: Nelson Braga
Cargo: Desenvolvedor Fullstack
Data: 22/03/2024 

*/

.btnRetornar {
    padding: 0.625em 3.125em 0.625em 3.125em;    
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    background-color: rgb(35, 212, 35);
    border: outset 0.0625em white;    
    border-radius: 0.625em;
}

.btnRetornar:hover {
    background-color: rgb(12, 163, 12);
    border: solid 0.125em white;
    box-shadow: 0.125em 0.125em 0.5em rgb(207, 206, 206);    
}